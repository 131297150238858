<template>
<div class="apprMind">
  <el-form class="content bascform">
  <div class="col col8">
  <div class="tableList content">
    <div class="table">
      <el-table row-key="supplierName" :data="tableData" border style="width: 100%">
        <el-table-column :show-overflow-tooltip="true" prop="supplierName" label="供应商名称"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" :key="index" v-for="(item,index) in scoreList" :prop="item.scoringId" :label="item.bidScoringMes"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="synthesisScore" label="综合得分"></el-table-column>
      </el-table>
    </div>
  </div>
  </div>
  </el-form>
  </div>
</template>
<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'ExpertRatingEnd',
  props: ['formData'],
  data () {
    return {
      tableData: [],
      scoreList: []
    }
  },
  created: function () {
    if (this.formData) {
      request('/api/bid/score?bidId=' + this.formData.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.scoreList = response.data
          var tempTableList = []
          var tempTeamList = new Set()
          // eslint-disable-next-line no-unused-vars
          for (var s = 0; s < this.scoreList[0].list.length; s++) {
            tempTableList.push({ supplierName: this.scoreList[0].list[s].supplierName, children: [] })
          }
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  tempTableList[g][d.scoringId] = sd.score
                  if (tempTableList[g].synthesisScore === undefined) {
                    tempTableList[g].synthesisScore = parseFloat(sd.score)
                  } else {
                    tempTableList[g].synthesisScore = parseFloat(tempTableList[g].synthesisScore) + parseFloat(sd.score)
                  }
                  if (isNaN(parseFloat(tempTableList[g].synthesisScore))) {
                    tempTableList[g].synthesisScore = 0
                  }
                  tempTableList[g].synthesisScore = tempTableList[g].synthesisScore.toFixed(2)
                  for (var v of sd.deptUsernameList) {
                    tempTeamList.add(v.deptUsername)
                  }
                }
              }
            }
          }
          for (var j of tempTableList) {
            for (var b of tempTeamList) {
              j.children.push({ supplierName: b })
            }
          }
          /* eslint-disable no-redeclare */
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  for (var v of tempTableList[g].children) {
                    for (var sv of sd.deptUsernameList) {
                      if (sv.deptUsername === v.supplierName) {
                        v[d.scoringId] = sv.personSc
                        if (v.synthesisScore === undefined) {
                          v.synthesisScore = parseFloat(sv.personSc)
                        } else {
                          v.synthesisScore = parseFloat(v.synthesisScore) + parseFloat(sv.personSc)
                        }
                        if (isNaN(parseFloat(v.synthesisScore))) {
                          v.synthesisScore = 0
                        }
                        v.synthesisScore = v.synthesisScore.toFixed(2)
                      }
                    }
                  }
                }
              }
            }
          }
          this.tableData = tempTableList
        }
      })
    }
  },
  watch: {
    formData: function (val) {
      request('/api/bid/score?bidId=' + val.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.scoreList = response.data
          var tempTableList = []
          var tempTeamList = new Set()
          // eslint-disable-next-line no-unused-vars
          for (var s = 0; s < this.scoreList[0].list.length; s++) {
            tempTableList.push({ supplierName: this.scoreList[0].list[s].supplierName, children: [] })
          }
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  tempTableList[g][d.scoringId] = sd.score
                  if (tempTableList[g].synthesisScore === undefined) {
                    tempTableList[g].synthesisScore = parseFloat(sd.score)
                  } else {
                    tempTableList[g].synthesisScore = parseFloat(tempTableList[g].synthesisScore) + parseFloat(sd.score)
                  }
                  if (isNaN(parseFloat(tempTableList[g].synthesisScore))) {
                    tempTableList[g].synthesisScore = 0
                  }
                  tempTableList[g].synthesisScore = tempTableList[g].synthesisScore.toFixed(2)
                  for (var v of sd.deptUsernameList) {
                    tempTeamList.add(v.deptUsername)
                  }
                }
              }
            }
          }
          for (var j of tempTableList) {
            for (var b of tempTeamList) {
              j.children.push({ supplierName: b })
            }
          }
          /* eslint-disable no-redeclare */
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  for (var v of tempTableList[g].children) {
                    for (var sv of sd.deptUsernameList) {
                      if (sv.deptUsername === v.supplierName) {
                        v[d.scoringId] = sv.personSc
                        if (v.synthesisScore === undefined) {
                          v.synthesisScore = parseFloat(sv.personSc)
                        } else {
                          v.synthesisScore = parseFloat(v.synthesisScore) + parseFloat(sv.personSc)
                        }
                        if (isNaN(parseFloat(v.synthesisScore))) {
                          v.synthesisScore = 0
                        }
                        v.synthesisScore = v.synthesisScore.toFixed(2)
                      }
                    }
                  }
                }
              }
            }
          }
          this.tableData = tempTableList
        }
      })
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/elForm.scss';
@import '@/assets/css/tableList.scss';
  .gradeMain{
    margin-top: 20px;
  }
  .gradeMain{
    :deep(.el-input){
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  :deep( .cell){
    padding-left: 0px;
    padding-right: 0px;
  }
  :deep( .el-table th){
    .cell{
      padding-left: 10px;
    }
  }
  :deep(.el-table td){
    // padding: 0px;
    .cell{
      padding-left: 10px;
    }
  }
  :deep(.el-input__inner){
    position: relative;
    top: 0px;
    height: 100%;
    padding: 0px;
  }
  }
</style>
